import axios from "axios";
import { host } from "../constants/constants";

const api = async (path, data, method) => {
  try {
    if (method === "POST") {
      const responseData = await axios.post(`${host}/${path}`, { data: data });
      return responseData?.data;
    } else {
      const responseData = await axios.get(`${host}/${path}`, 
        {
        headers: {
          "ngrok-skip-browser-warning": "ngrok",
        },
      }
    );
      return responseData?.data;
    }
  } catch (err) {
    return err?.response?.data?.message;
  }
};

export default api;
