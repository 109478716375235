import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./layouts/Navbar";
import Sidebar from "./layouts/Sidebar";
import Filter from "./pages/Filter";
import api from "./api/api";
import LoadingScreen from "./components/LoadingScreen";
import Searchbar from "./components/Searchbar";
function App() {
  const [categoryData, setCategoryData] = useState([]);
  const [softwareData, setSoftwareData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSoftwareData, setSelectedSoftwareData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedItemUrl, setSelectedItemUrl] = useState(null);
  const isFirstRender = useRef(true);

  const location = window.location.pathname;
  useEffect(() => {
    const getCategoryData = async () => {
      const response = await api("filters", "", "GET");
      // console.log(response, "filter response");
      setCategoryData(response);
      // const softwareResponse = await api("software", "", "GET");
      // setSoftwareData(softwareResponse);
      // console.log(location, "location");
      const pathSegments = location.split("/").filter(Boolean);
      // console.log(pathSegments, "pathSegments");
      if (pathSegments.length > 0) {
        const _selectedCategories = response
          ?.filter((category) => pathSegments.includes(category.url_slug))
          ?.map((category) => category.value);
        // console.log(_selectedCategories, "selectedCategories");
        setSelectedCategories(_selectedCategories);
        if (pathSegments.length !== _selectedCategories.length) {
          const _selectedItemUrl = pathSegments[pathSegments.length - 1];
          setSelectedItemUrl(_selectedItemUrl);
          // console.log(_selectedItemUrl, "selectedItemUrl");
        }
      } else {
        setSelectedCategories([]);
      }
    };

    getCategoryData();
  }, []);

  useEffect(() => {
    const getSelectedSoftwareData = async () => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      setLoading(false);
      const response = await api(
        "filters/software",
        selectedCategories,
        "POST"
      );
      const _selectedItem = response.find((item) => item.url_slug === selectedItemUrl);
      // console.log(_selectedItem, "selectedItem");
      _selectedItem ? setSelectedItem(_selectedItem.id) : setSelectedItem(0);
      // console.log(response, "software response");
      setSelectedSoftwareData(response);

      setLoading(true);
    };

    getSelectedSoftwareData();
  }, [selectedCategories]);

  const onSearch = (searchTerm) => {
    // console.log(searchTerm);
    let newSelectedCategories = [];
    newSelectedCategories = selectedCategories.includes(searchTerm)
      ? selectedCategories
      : [searchTerm, ...selectedCategories];
    setSelectedCategories((prevCategories) => newSelectedCategories);
    let newPath = "/";
    newSelectedCategories.forEach((category) => {
      newPath =
        `/${categoryData.find((item) => item.value === category).url_slug}` +
        newPath;
    });
    setSelectedItem(0);
    window.history.pushState(null, "", newPath);
  };

  return (
    <BrowserRouter>
      <Navbar />
      <Sidebar
        categoryData={categoryData}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        setSelectedItem={setSelectedItem}
      />
      <Searchbar
        onSearch={onSearch}
        selectedCategories={selectedCategories}
        selectedSoftwareData={selectedSoftwareData}
        categoryData={categoryData}
        setSelectedItem={setSelectedItem}
        softwareData={softwareData}
      />
      {loading ? "" : <LoadingScreen />}
      <Routes>
        <Route
          path="*"
          element={
            <Filter
              selectedCategories={selectedCategories}
              selectedSoftwareData={selectedSoftwareData}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              categoryData={categoryData}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
