import { useState, useEffect, useRef } from "react";
import { host } from "../constants/constants";
import { useNavigate } from "react-router-dom";
import { parseDescription } from "../helper/parseDescription";
function Filter({
  selectedCategories,
  selectedSoftwareData,
  selectedItem,
  setSelectedItem,
  categoryData,
}) {
  const [selectedItemData, setSelectedItemData] = useState({});
  const [parsedDescription, setParsedDescription] = useState({});
  const isFirstRender = useRef(true);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(selectedItem, "start console");
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    console.log("next console");
    if (selectedItem === 0) {
      let newPath = "";
      selectedCategories.forEach((category) => {
        newPath =
          `/${categoryData.find((item) => item.value === category).url_slug}` +
          newPath;
      });
      navigate(newPath);
    } else {
      const clickedItem = selectedSoftwareData.find(
        (item) => item.id === selectedItem
      );
      setSelectedItemData(clickedItem);
      const _parsedDescription = parseDescription(clickedItem.description);
      setParsedDescription(_parsedDescription);
      console.log(_parsedDescription, "parsedDescription");
      let newPath = "";
      selectedCategories.forEach((category) => {
        newPath =
          `/${
            categoryData.find(
              (item) => item.value.toLowerCase() === category.toLowerCase()
            ).url_slug
          }` + newPath;
      });
      newPath = newPath + `/${clickedItem.url_slug}`;
      navigate(newPath);
    }
  }, [selectedItem]);

  const handleCategoryClick = (id) => {
    console.log(id, "id");
    setSelectedItem(id);
  };

  const handleBackButton = () => {
    setSelectedItem(0);
  };

  return selectedItem === 0 ? (
    <div className="p-4 sm:ml-72">
      <div className="p-4 min-h-[85vh] rounded-lg">
        <div className="grid grid-cols-3 gap-4 mb-4">
          {selectedSoftwareData?.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-center h-24 pl-2 pr-2 cursor-pointer rounded hover:bg-gray-300 bg-gray-200"
              onClick={() => handleCategoryClick(item.id)}
            >
              <div className="flex items-center justify-center w-1/4">
                <img
                  src={`${host}/uploads/${item.img_url}`}
                  alt={item.name}
                  className="w-12 h-12"
                />
              </div>
              <div className="flex items-center w-3/4">
                <div className="flex flex-col">
                  <p className="text-2xl text-black ml-2">{item.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div className="p-4 sm:ml-72">
      <button
        onClick={() => handleBackButton()}
        className="mb-4 px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-lg flex items-center justify-end"
      >
        <svg
          className="w-5 h-5 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M10 19l-7-7m0 0l7-7m-7 7h18"
          />
        </svg>
        Back
      </button>
      <div className="p-4 min-h-[83vh] rounded-lg">
        {/* <p className="text-2xl mt-6">{selectedItemData.description}</p> */}
        <div className="max-w-5xl mx-auto p-6 space-y-6">
          <h1 className="text-4xl font-bold">{selectedItemData.name}</h1>

          <a
            href={selectedItemData.website}
            rel="noreferrer noopener"
            target="_blank"
            className="w-36 h-10 mt-6 text-white cursor-pointer text-1xl bg-green-500 rounded-lg flex items-center justify-center group"
          >
            Visit Website
            <svg
              className="w-5 h-5 ml-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </a>
          <h1 className="text-2xl font-bold">{parsedDescription?.title}?</h1>

          {parsedDescription?.description?.map((paragraph, index) => (
            <p key={index} className="text-sm">
              {paragraph}
            </p>
          ))}

          <div className="bg-yellow-50 p-4 rounded-lg">
            <h2 className="text-xl font-semibold mb-2">
              {parsedDescription?.featuresTitle}
            </h2>
            <p className="mb-2">{parsedDescription?.featuresDescription}</p>
            <ul className="list-none space-y-1">
              {parsedDescription?.features?.map((feature, index) => (
                <li key={index} className="flex items-start">
                  <span className="text-green-500 mr-2">✔️</span>
                  {feature}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <p className="font-semibold mb-2">
              {parsedDescription?.searchTermsTitle}
            </p>
            <div className="flex flex-wrap gap-2">
              {parsedDescription?.searchTerms &&
                parsedDescription?.searchTerms?.map((term, index) => (
                  <span
                    key={index}
                    className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded"
                  >
                    {term}
                  </span>
                ))}
            </div>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">
              {parsedDescription?.useCasesTitle}
            </h2>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">
              {parsedDescription?.usersTitle}
            </h3>
            <p className="mb-2">{parsedDescription?.usersDescription}</p>
            <div className="flex flex-wrap gap-2">
              {parsedDescription?.users &&
                parsedDescription?.users?.map((user, index) => (
                  <span
                    key={index}
                    className="bg-blue-100 text-blue-800 text-sm px-3 py-1 rounded-full"
                  >
                    {user}
                  </span>
                ))}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold">
              {parsedDescription?.supportTitle}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
