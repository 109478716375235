import React, { useState, useRef, useEffect } from "react";

const Searchbar = ({ onSearch, categoryData, softwareData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const results1 = categoryData.filter((item) =>
      item.value.toLowerCase().includes(searchValue.toLowerCase())
    );
    // const results2 = softwareData.filter((item) =>
    //   item.name.toLowerCase().includes(searchValue.toLowerCase())
    // );
    // const totalResults = [...results1, ...results2];
    setSearchResults(results1);
    setIsDropdownOpen(results1.length > 0);
  };
  const searchSoftware = (result) => {
    onSearch(result);
    setSearchTerm("");
  };
  return (
    <div className="relative sm:ml-72" ref={dropdownRef}>
      <div className="flex items-center justify-center">
        <div className="mt-4 w-9/12">
          <div className="flex px-4 py-3 rounded-md border-2 border-blue-500 overflow-hidden w-full font-[sans-serif]">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search Something..."
              className="w-full outline-none bg-transparent text-gray-600 text-sm"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 192.904 192.904"
              width="16px"
              className="fill-gray-600"
            >
              <path d="m190.707 180.101-47.078-47.077c11.702-14.072 18.752-32.142 18.752-51.831C162.381 36.423 125.959 0 81.191 0 36.422 0 0 36.423 0 81.193c0 44.767 36.422 81.187 81.191 81.187 19.688 0 37.759-7.049 51.831-18.751l47.079 47.078a7.474 7.474 0 0 0 5.303 2.197 7.498 7.498 0 0 0 5.303-12.803zM15 81.193C15 44.694 44.693 15 81.191 15c36.497 0 66.189 29.694 66.189 66.193 0 36.496-29.692 66.187-66.189 66.187C44.693 147.38 15 117.689 15 81.193z"></path>
            </svg>
          </div>
        </div>
      </div>
      {isDropdownOpen && (
        <div className="flex justify-center">
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300  w-9/12 rounded-md shadow-lg">
            {searchResults.map((result, index) => (
              <div
                key={index}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  setSearchTerm(result.value ? result.value : result.name);
                  setIsDropdownOpen(false);
                  searchSoftware(result.value ? result.value : result.name);

                }}
              >
                {result.value ? result.value : result.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Searchbar;
