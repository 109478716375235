import React from "react";
import { useNavigate } from "react-router-dom";

function Sidebar({ categoryData, selectedCategories, setSelectedCategories, setSelectedItem }) {
  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    // Access the checkbox's checked status
    // console.log(selectedCategories);
    let newSelectedCategories = [];
    if (event.target.checked === true) {
      newSelectedCategories = selectedCategories.includes(event.target.id)
        ? selectedCategories
        : [event.target.id, ...selectedCategories];
      setSelectedCategories((prevCategories) => newSelectedCategories);
    } else {
      newSelectedCategories = selectedCategories.filter(
        (categoryId) => categoryId !== event.target.id
      );
      setSelectedCategories((prevCategories) => newSelectedCategories);
    }
    setSelectedItem(0);
    let newPath = "";
    newSelectedCategories.forEach((category) => {
      newPath =
        `/${categoryData.find((item) => item.value === category).url_slug}` +
        newPath;
    });
    navigate(newPath);
  };

  const handleLabelMouseDown = (event) => {
    event.preventDefault();
  };
  return (
    <aside
      id="default-sidebar"
      className="fixed top-0 left-0 z-40 w-72 h-screen transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50">
        {selectedCategories.length > 0 ? (
          <>
            {" "}
            <p className="ms-3 font-bold pb-3">Active Filters</p>
            <ul className="bg-white space-y-2 font-medium border rounded-lg shadow">
              <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="text-lg font-semibold mb-2">Remove</h3>
                {selectedCategories?.map((category, index) => (
                  <li key={index} className="pl-3 pt-2">
                    <div className="flex flex-wrap gap-2">
                      <label
                        onMouseDown={handleLabelMouseDown}
                        htmlFor={category}
                        className="flex items-center justify-center cursor-pointer pointer-events-auto hover:bg-gray-300 bg-gray-200 rounded-full w-full px-3 py-1 text-sm"
                      >
                        {category}
                      </label>
                    </div>
                  </li>
                ))}
              </div>
            </ul>
          </>
        ) : (
          ""
        )}

        <ul className="bg-white space-y-2 font-medium border mt-5 rounded-lg shadow">
          {categoryData?.map((category, index) => (
            <li key={index}>
              <div className="flex items-center p-2 pl-10 text-gray-900 rounded-lg hover:bg-gray-100 group">
                <input
                  type="checkbox"
                  id={category.value}
                  onChange={handleCheckboxChange}
                  checked={selectedCategories.includes(category.value)}
                />
                <label
                  htmlFor={category.value}
                  className="ms-2 w-full text-sm font-medium text-gray-900 cursor-pointer"
                >
                  {category.value}
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
}

export default Sidebar;
