export const parseDescription = (text) => {
    const lines = text.split('\n').map(line => line.trim()).filter(line => line);
  
    const data = {
      title: lines[0].replace('?', '').trim(),
      description: [],
      features: [],
      searchTerms: [],
      users: []
    };
  
    let currentSection = 'description';
  
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i];
  
      if (line.startsWith('⭐')) {
        currentSection = 'features';
        data.featuresTitle = line;
      } else if (line.startsWith('People also search for:')) {
        currentSection = 'searchTerms';
        data.searchTermsTitle = line;
      } else if (line.startsWith('⚙️')) {
        data.useCasesTitle = line;
      } else if (line.startsWith('🙋‍♂️')) {
        currentSection = 'users';
        data.usersTitle = line;
      } else if (line.startsWith('ℹ️')) {
        data.supportTitle = line;
        data.supportDescription = lines[i + 1];
        data.supportLink = lines[i + 2];
        break;
      } else if (currentSection === 'description') {
        data.description.push(line);
      } else if (currentSection === 'features') {
        if (line.startsWith('✔️')) {
          data.features.push(line.replace('✔️', '').trim());
        } else if (!data.featuresDescription) {
          data.featuresDescription = line;
        }
      } else if (currentSection === 'searchTerms') {
        data.searchTerms = line.split(' ');
      } else if (currentSection === 'users') {
        if (!data.usersDescription) {
          data.usersDescription = line;
        } else {
          // Capture all remaining lines as users until we hit a new section
          while (i < lines.length && !lines[i].startsWith('ℹ️')) {
            data.users = data.users.concat(lines[i].split(' '));
            i++;
          }
          i--; // Adjust index as we've gone one line too far
        }
      }
    }
  
    // Remove any empty strings from users array
    data.users = data.users.filter(user => user.trim() !== '');
  
    return data;
  }
  
